










































































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import { IntroPopup } from '@/domain/introPopup/model/introPopup';

export default Vue.extend({
  name: 'IntroPopupList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    introPopupList: {
      type: Array,
      default: () => [],
    },
    introPopupTotal: Number,
  },
  data(): {
    loading: boolean;
    introPopups: IntroPopup[];
  } {
    return {
      loading: false,
      introPopups: this.introPopupList as IntroPopup[],
    };
  },
  computed: {
    model: {
      get(): any[] {
        return (this as any).introPopupList;
      },
    },
  },
  activated() {
    this.$emit('syncList');
  },
  methods: {
    changeExposed(introPopup: IntroPopup, exposed: boolean): void {
      this.$emit('exposureIntroPopup', { introPopup, exposed });
    },
    exposureReservation(introPopup: IntroPopup): boolean {
      const currentTime = new Date().getTime();
      const startedAt = new Date(introPopup.startedAt).getTime();

      if (currentTime < startedAt) {
        return true;
      }

      return false;
    },
    detailIntroPopup(id: number) {
      this.$router.push(`/introPopup/detail/${id}`);
    },
  },
});
