








































































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import draggable from 'vuedraggable';
import {
  IntroPopup,
  IntroPopupChangedIndexNoParam,
  IntroPopupExposureParam,
} from '@/domain/introPopup/model/introPopup';
import { IntroPopupService } from '@/domain/introPopup/service/IntroPopupService';
import { apolloClient } from '@/apolloClient';

const introPopupService = new IntroPopupService(apolloClient);

export default Vue.extend({
  name: 'IntroPopupExposureList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    draggable,
  },
  props: {
    exposureIntroPopups: {
      type: Array,
      default: () => [],
    },
    dropIntroPopup: {
      type: Array,
      default: () => [],
    },
  },
  data(): {
    loading: boolean;
    exposeLoading: boolean;
    unexposedIntroPopups: any[];
  } {
    return {
      loading: false,
      exposeLoading: false,
      unexposedIntroPopups: this.dropIntroPopup,
    };
  },
  computed: {
    model: {
      get(): any[] {
        return (this as any).exposureIntroPopups;
      },
      set(newValue) {
        (this as any).$emit('updateExposedIntroPopup', newValue);
      },
    },
  },
  methods: {
    removeExposedIntroPopup(index: number, introPopup: IntroPopup) {
      //삭제시 노출 팝업 리스트에서 제거, 상태값 변경
      this.exposureIntroPopups.splice(index, 1);
      this.$emit('removeExposedIntroPopup', introPopup.id);
    },
    registerIntroPopup() {
      this.$router.push('/introPopup/register');
    },
    updateExposurePopup() {
      this.$modal.show(
        {
          title: '노출 순서 수정',
          message: '노출 순서를 수정하시겠습니까?',
          type: 'info',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            const param = await Promise.all(
              this.exposureIntroPopups.map(
                async (introPopup: any, index: any) => {
                  const id = introPopup.id.toString();
                  const introPopupExposureParam: IntroPopupExposureParam = {
                    id,
                    exposed: true,
                  };

                  await introPopupService.exposeIntroPopup(
                    introPopupExposureParam
                  );

                  return {
                    id,
                    indexNo: index + 1,
                  };
                }
              )
            );

            await introPopupService.changeIntroPopupIndexNo(param);

            //노출 상태값 변경 (노출 하지 않은 팝업은 상태값을 false로 내린다.);
            for (const introPopupId of this.$data.unexposedIntroPopups) {
              const param: IntroPopupExposureParam = {
                id: introPopupId,
                exposed: false,
              };

              await introPopupService.exposeIntroPopup(param);
            }

            this.$modal.show({
              title: '노출 순서 변경 성공!',
              message: '노출 순서를 변경하였습니다.',
              type: 'success',
            });

            this.$emit('refresh');
          } catch (err) {
            this.$modal.show({
              title: '노출 순서 수정 실패!',
              message: '수정 실패 하였습니다.',
              type: 'warning',
            });
          }
        },
        () => {
          return;
        }
      );
    },
  },
});
