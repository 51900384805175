

































import Vue from 'vue';
import IntroPopupExposureList from '@/domain/introPopup/components/IntroPopupExposureList.vue';
import IntroPopupList from '@/domain/introPopup/components/IntroPopupList.vue';
import {
  IntroPopup,
  IntroPopupConnection,
  IntroPopupEdge,
  IntroPopupFilter,
} from '@/domain/introPopup/model/introPopup';
import {
  EXPOSURED_INTRO_POPUPS,
  INTRO_POPUPS,
} from '@/domain/introPopup/queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';

export default Vue.extend({
  name: 'IntroPopupListContainer',
  components: {
    IntroPopupExposureList,
    IntroPopupList,
  },
  data(): {
    loading: boolean;
    exposeLoading: boolean;
    filter: IntroPopupFilter;
    page: null | number;
    pageSize: null | number;
    introPopups: IntroPopup[];
    totalCount: number;
    introPopup: IntroPopup;
    exposuredIntroPopups: IntroPopup[];
    unexposedIntroPopupIds: number[];
  } {
    return {
      loading: false,
      exposeLoading: false,
      filter: {},
      page: Number(this.$route.query.page) || 1,
      pageSize: Number(this.$route.query.pageSize) || 20,
      introPopups: [],
      totalCount: 0,
      introPopup: {} as IntroPopup,
      exposuredIntroPopups: [],
      unexposedIntroPopupIds: [],
    };
  },
  methods: {
    exposuredIntroPopup(introPopup: IntroPopup): void {
      //노출 팝업 등록 노출시간 validation 추가
      if (!(this as any).validationExposedIntroPopup(introPopup)) {
        this.$modal.show({
          title: '노출 팝업 등록 실패!',
          type: 'warning',
          message: '노출 시간을 체크해주세요!',
        });

        return;
      }

      if (
        this.$data.exposuredIntroPopups[0]?.contentType !==
          introPopup.contentType &&
        this.$data.exposuredIntroPopups.length > 0
      ) {
        this.$modal.show({
          title: '노출 팝업 등록 실패!',
          type: 'warning',
          message: '동일한 타입만 노출 가능합니다.',
        });

        return;
      }

      this.$data.exposuredIntroPopups.push(introPopup);
      introPopup.exposed = true;

      /**
       * 삭제된 introPopup을 관리하는 array
       */

      for (const [
        index,
        introPopupId,
      ] of this.$data.unexposedIntroPopupIds.entries()) {
        if (introPopup.id === introPopupId) {
          this.$data.unexposedIntroPopupIds.splice(index, 1);
        }
      }
    },
    updateExposedIntroPopup(exposedPopupList: IntroPopup[]): void {
      this.$data.exposuredIntroPopups = exposedPopupList;
    },
    validationExposedIntroPopup(introPopup: IntroPopup): boolean {
      const startedAt = new Date(introPopup.startedAt).getTime();
      const endedAt = new Date(introPopup.endedAt).getTime();
      const currentDate = new Date().getTime();

      if (startedAt < currentDate && endedAt > currentDate) {
        return true;
      }

      return false;
    },
    removeExposedIntroPopup(id: number): void {
      this.$data.unexposedIntroPopupIds.push(id);

      const unexposedIntroPopup = this.$data.introPopups.find(
        (introPopup: any) => {
          if (introPopup.id === id) {
            return introPopup;
          }
        }
      );

      if (unexposedIntroPopup) {
        unexposedIntroPopup.exposed = false;
      }
    },
    getIntroPopupList(): void {
      this.$apollo.queries.introPopups.refetch();
    },
    getExposureIntroPopup(): void {
      this.$apollo.queries.exposuredIntroPopups.refetch();
    },
    changeExposureIntroPopup(introPopupInfo: {
      introPopup: IntroPopup;
      exposed: boolean;
    }): void {
      console.log(introPopupInfo);
      if (introPopupInfo.exposed) {
        (this as any).exposuredIntroPopup(introPopupInfo.introPopup);
      } else {
        const unexposedIntroPopupIndex =
          this.$data.exposuredIntroPopups.findIndex(
            (introPopup: IntroPopup) => {
              return introPopup.id === introPopupInfo.introPopup.id;
            }
          );

        (this as any).$refs.exposure.removeExposedIntroPopup(
          unexposedIntroPopupIndex,
          introPopupInfo.introPopup
        );
      }
    },
  },
  apollo: {
    exposuredIntroPopups: {
      query: EXPOSURED_INTRO_POPUPS,
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          board: { exposuredIntroPopups: IntroPopupConnection };
        }>
      ) {
        this.$data.exposuredIntroPopups =
          result.data.board.exposuredIntroPopups;

        this.$data.exposeLoading = true;
      },
      update: data => data.board.exposuredIntroPopups,
    },
    introPopups: {
      query: INTRO_POPUPS,
      variables() {
        return {
          filter: this.$data.filter,
          page: Number(this.$data.page),
          size: Number(this.$data.pageSize),
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          board: { introPopups: IntroPopupConnection };
        }>
      ) {
        this.$data.totalCount = result.data.board.introPopups.totalCount;
        this.$data.introPopups = result.data.board.introPopups.edges.map(
          (edge: IntroPopupEdge) => {
            /**
             * 기존에 노출된 팝업을 다른 페이지에서 삭제한뒤 돌아오면 팝업 상태가 이미 노출이 되어있는 이슈 발생으로
             * unexposedIntroPopupIds
             */

            if (this.$data.unexposedIntroPopupIds.length === 0) {
              return edge.node;
            }

            const existentRemoveId = this.$data.unexposedIntroPopupIds.indexOf(
              edge.node.id
            );

            if (existentRemoveId > -1) {
              edge.node.exposed = false;
            }

            return edge.node;
          }
        );
        this.$data.loading = true;
      },
      fetchPolicy: 'no-cache',
      update: data => {
        data.board.introPopups.edges.map((popup: any) => {
          const currentTime = new Date().getTime();
          const introPopup = popup.node;

          if (
            introPopup.exposed &&
            (introPopup.endedAt < currentTime ||
              introPopup.startedAt > currentTime)
          ) {
            introPopup.exposed = false;
          }
        });
      },
    },
  },
});
